<script>
import {onMounted, reactive, toRefs} from 'vue';
import router from "../../../router";
import QRCodeVue3 from "qrcode-vue3";
import PeriodicalUpdater from "../../Updater/PeriodicalUpdater.vue";

export default {
  name: 'OrderDetail',
  components: {
    PeriodicalUpdater,
    QRCodeVue3
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const data = reactive({
      order: null,
      router: router
    });

    function load() {
      let xhrData = {action: 'getOrder', guid: props.guid};
      window.helpers.server.fetchPost(router.front.xhr.order, xhrData)
          .then((order) => {
            data.order = order;
          });
    }

    onMounted(_ => {
      load();
    });

    return {
      ...toRefs(data),
      load
    }
  }
}
</script>

<template>
  <div class="order-detail" v-if="order !== null">
    <h3>Objednávka č. {{ order.number }}</h3>
    <el-descriptions :column="1" border>
      <el-descriptions-item label="Den">{{ order.data.day }}</el-descriptions-item>
      <el-descriptions-item label="Umístění">{{ order.data.seat }}</el-descriptions-item>
      <el-descriptions-item label="Počet dospělých osob">{{ order.data.adultCount }}</el-descriptions-item>
      <el-descriptions-item label="Počet dětí do 15 let">{{ order.data.childCount }}</el-descriptions-item>
      <el-descriptions-item label="Jméno a příjmení">{{ order.fullName }}</el-descriptions-item>
      <el-descriptions-item label="E-mail">{{ order.email }}</el-descriptions-item>
      <el-descriptions-item label="Telefon">{{ order.phone }}</el-descriptions-item>
      <el-descriptions-item label="Celková cena">{{ order.price }} Kč</el-descriptions-item>
      <el-descriptions-item label="Stav">
        <span class="d-flex align-items-center gap-1">
        <template v-if="order.state === 'waitingforpayment'"><periodical-updater :loading-func="load" reload-ms="15000" auto-start></periodical-updater></template>
        <span>{{ order.stateString }}</span>
        </span>
      </el-descriptions-item>
      <el-descriptions-item v-if="order.isPaid" label=""><a :href="router.front.ticket.pdfTicket(order.guid, false)"><el-button type="primary">Zobrazit vstupenky + fakturu v PDF</el-button></a></el-descriptions-item>
      <el-descriptions-item v-if="order.isPaid" label=""><a :href="router.front.ticket.pdfTicket(order.guid, true)"><el-button type="success">Stáhnout vstupenky + fakturu v PDF</el-button></a></el-descriptions-item>
    </el-descriptions>

    <div v-if="order.state === 'waitingforpayment'" class="mt-3">
      <h3>Zbývá doplatit {{ order.toPay }} Kč</h3>
      <p>Platbu proveďte prosím na:</p>
      <el-descriptions :column="1" border>
        <el-descriptions-item label="Účet">{{ order.payAccount }}</el-descriptions-item>
        <el-descriptions-item label="Kód banky">{{ order.payCode }}</el-descriptions-item>
        <el-descriptions-item label="Variabilní symbol">{{ order.number }}</el-descriptions-item>
        <el-descriptions-item label="IBAN">{{ order.payIban }}</el-descriptions-item>
        <el-descriptions-item label="BIC">{{ order.payBic }}</el-descriptions-item>
      </el-descriptions>

      <p class="mt-3">nebo odešlete platbu načtením QR kódu ve vaším bankovní aplikaci:</p>
      <div class="qr-code-img">
        <QRCodeVue3
            :key="order.qrCodeData"
            :value="order.qrCodeData"
            :cornersSquareOptions="{ type: 'square'}"
            :dotsOptions="{ type: 'square' }"
        />
      </div>

      <h4 class="mt-3 border-bottom border-success">
        <span class="fa-stack small text-success mb-1">
          <em class="far fa-circle fa-stack-2x"></em>
          <em class="fa-solid fa-info fa-stack-1x"></em>
        </span>
        Po připsání celkové ceny na náš účet vám bude zaslán e-mail se vstupenkami a fakturou.</h4>
    </div>


  </div>
</template>

<style>
.order-detail {
  .el-descriptions__label, .el-descriptions__content, .el-descriptions__cell, .el-button {
    font-size: 1.25rem !important;
  }

  .el-descriptions__label {
    font-weight: normal !important;
  }

  margin-bottom: 5rem;
}
</style>

<style scoped>
.qr-code-img {
  display: flex;
  justify-content: center;
}
</style>