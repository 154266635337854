<script>
import {onMounted, reactive, toRefs} from 'vue';
import DriversClubForm from "../../../Forms/Registration/DriversClubForm.vue";
import DriversClubDetail from "./DriversClubDetail.vue";
import router from "../../../router";

export default {
  name: 'DriversClubWrapper',
  components: {DriversClubDetail, DriversClubForm},
  props: {
    driverGuid: {
      type: String,
      required: true
    },
    driversClubId: {
      type: Number,
      required: false,
      default: null
    },
    price: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const data = reactive({
      driversClub: null,
    });

    function driversClubSaved(driversClub) {
      data.driversClub = driversClub;
    }

    onMounted(() => {
      if (props.driversClubId !== null) {
        let formData = {
          action: 'clubDetail',
          id: props.driversClubId
        };
        window.helpers.server.fetchPost(router.registration.xhr.driver, formData)
            .then(res => {
              data.driversClub = res;
            });
      }
    });

    return {
      ...toRefs(data),
      driversClubSaved
    }
  }
}
</script>

<template>
  <p>Nabídka cateringových služeb pro účastníky:</p>
  <p>V rámci zjednodušení Vám nabízíme možnost zakoupení vstupu do Drivers Clubu pro mechaniky či Vaše hosty.</p>
  <p>Za cenu {{ price }} Kč  vč. DPH za celý balíček (pátek večer a sobota / neděle viz níže) na osobu.</p>
  <p>Každý jezdec má v rámci svého zaplaceného startovného zajištěný vstup do Drivers Clubu které obsahuje:</p>
  <p><strong>Pátek </strong>od 18:00 do 20:00 zahajovací večer – „lehké občerstvení z Grilu“</p>
  <p><strong>Sobota: </strong></p>
  <ul>
    <li>snídaně polévka + sladké</li>
    <li>oběd</li>
    <li>odpolední svačina</li>
    <li>káva + nealko pití</li>
  </ul>
  <p><strong>Neděle:</strong></p>
  <ul>
    <li>snídaně polévka + sladké</li>
    <li>oběd</li>
    <li>odpolední svačina</li>
    <li>káva + nealko pití</li>
  </ul>

  <drivers-club-form v-if="driversClub === null" :driver-guid="driverGuid" @saved="driversClubSaved" :price="price"></drivers-club-form>
  <drivers-club-detail v-else :drivers-club="driversClub" :price="price"></drivers-club-detail>
</template>

<style scoped>

</style>