<script>
import {reactive, toRefs} from 'vue';
import router from "../../../router";

export default {
  name: 'DriverMoreInfo',
  props: {
    driver: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const data = reactive({
      elForm: null,
      form: {
        arrival: null,
        carTrailer: 0,
        carVan: 0,
        caravan: 0,
        location: null,
      },
    });

    function validateCars(rule, value, callback) {
      let filled = data.form.carTrailer > 0 || data.form.carVan > 0 || data.form.caravan > 0;

      if (!filled) {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    }

    async function submit() {
      await data.elForm.validate(valid => {
        if (valid) {
          window.helpers.server
              .fetchPost(router.registration.xhr.driver, {
                action: 'moreInfoForm',
                guid: props.driver.guid,
                ...data.form
              });
        }
      });
    }

    return {
      ...toRefs(data),
      validateCars, submit
    }
  }
}
</script>

<template>
  <h2><em class="fal fa-siren-on"></em> Formulář k dokončení</h2>
  <el-form ref="elForm" :model="form" label-width="150px">
    <el-form-item label="Kdy přijedete" prop="arrival" :rules="[ { required: true,  message: 'Vyplňte kdy přijede' } ]">
      <el-radio-group v-model="form.arrival">
        <el-radio-button label="first">Čtvrtek 15. 5.</el-radio-button>
        <el-radio-button label="second">Pátek 16. 5.</el-radio-button>
      </el-radio-group>
    </el-form-item>

    <el-divider content-position="left">Čím přijedete?</el-divider>

    <el-form-item label="Vůz s vlekem" prop="carTrailer" :rules="[ { required: true, trigger: 'change', message: 'Vyplňte alespoň jeden dopravní prostředek kterým přijedete', validator: validateCars  } ]">
      <el-input-number v-model="form.carTrailer" :min="0" :max="5" :step="1"></el-input-number>
    </el-form-item>

    <el-form-item label="Dodávka" prop="carVan" :rules="[ { required: true, trigger: 'change', message: 'Vyplňte alespoň jeden dopravní prostředek kterým přijedete', validator: validateCars } ]">
      <el-input-number v-model="form.carVan" :min="0" :max="5" :step="1"></el-input-number>
    </el-form-item>

    <el-form-item label="Obytný vůz" prop="caravan" :rules="[ { required: true, trigger: 'change', message: 'Vyplňte alespoň jeden dopravní prostředek kterým přijedete', validator: validateCars } ]">
      <el-input-number v-model="form.caravan" :min="0" :max="5" :step="1"></el-input-number>
    </el-form-item>

    <el-divider content-position="left"></el-divider>

    <el-form-item label="Umśtění" prop="location" :rules="[ { required: true,  message: 'Vyplňte umístění' } ]">
      <el-radio-group v-model="form.location" class="d-flex flex-column align-items-start">
        <el-radio label="in">Budu kempovat v areálu. Dodávka, vůz + přívěs, obytný automobil.</el-radio>
        <el-radio label="out">Budu spát mimo areál. Požaduji vjezd na vůz</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="submit">Odeslat formulář</el-button>
    </el-form-item>

  </el-form>
</template>

<style scoped>

</style>